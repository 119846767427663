import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { UILibModule } from '../uilib/uilib.module';
import { MyreportsComponent } from './myreports.component';
import { MyReportsDialogModule } from './myreportsdialog.module';

const routes = [
  { path: '', component: MyreportsComponent }
];

@NgModule({
  declarations: [
    MyreportsComponent
  ],
  imports: [
    CommonModule,
    UILibModule,
    DragDropModule,
    FormsModule,
    MyReportsDialogModule,
    RouterModule.forChild(routes),
  ]
})
export class MyreportsModule { }
