import { Component, OnInit, ViewChild } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { BreadcrumbService } from "../app-breadcrumb/breadcrumb.service";
import { ReportService } from "./report.service";
import { Section } from "./report.model";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { DialogComponent } from "../uilib/dialog/dialog.component";
import { MyReportsIndicatorItemSelectionDialogComponent } from "./myreportsindicatoritemselectiondialog/myreportsindicatoritemselectiondialog.component";
import { AppConfig } from "../app.config";

@Component({
  selector: "app-myreports",
  templateUrl: "./myreports.component.html",
  styleUrls: ["./myreports.component.css"],
})
export class MyreportsComponent implements OnInit {
  @ViewChild("removeAllDialog", { static: true })
  removeAllDialog: DialogComponent;
  @ViewChild("removeSectionDialog", { static: true })
  removeSectionDialog: DialogComponent;
  @ViewChild("myReportsIndicatorItemSelectionDialog", { static: true })
  _myReportsIndicatorItemSelectionDialog: MyReportsIndicatorItemSelectionDialogComponent;

  sections: Section[];
  reportTitle = "";
  myReportsEnabled: boolean = false;
  selectedSection: Section;
  title: string;

  constructor(
    private breadcrumbService: BreadcrumbService,
    private reportService: ReportService,
    private titleService: Title
  ) {
    this.title = "My Report";
  }

  ngOnInit() {
    this.myReportsEnabled = AppConfig.settings.featureSettings.enableMyReport;
    this.breadcrumbService.updateBreadCrumbs([]);
    this.reportTitle = this.reportService.getReportTitle();
    this.sections = this.reportService.getSections();
    this.titleService.setTitle(
      `${AppConfig.settings.constants.defaultPageTitle} - ${this.title}`
    );
  }

  ngOnDestroy() {
    this.titleService.setTitle(AppConfig.settings.constants.defaultPageTitle);
  }

  getContent(section: Section): string {
    return [
      section.graph && "Graph",
      section.data && "Data table",
      section.commentary && "Commentary",
      section.summary && "Notes Summary",
      section.sources && "Sources",
      section.definitions && "Definitions",
      section.content && "Inclusions & Exclusions",
      section.coding && "Coding",
      section.methods && "Statistical methods",
      section.dimensions && "Dimensions/Variables",
      section.references && "References",
    ]
      .filter((x) => !!x)
      .join(", ");
  }

  drop(event: CdkDragDrop<Section[]>) {
    moveItemInArray(this.sections, event.previousIndex, event.currentIndex);
    this.reportService.updateSections(this.sections);
  }

  openRemoveAllDialog() {
    this.removeAllDialog.open();
  }

  removeAll() {
    this.reportService.removeAllSections();
    this.sections = this.reportService.getSections();
    this.removeAllDialog.close();
  }

  openRemoveSectionDialog(section: Section) {
    this.selectedSection = section;
    this.removeSectionDialog.open();
  }

  removeSection(section: Section) {
    this.reportService.removeSection(section.id);
    this.sections = this.reportService.getSections();
    this.removeSectionDialog.close();
  }

  openEditSectionDialog(section: Section) {
    this.selectedSection = JSON.parse(JSON.stringify(section));
    this._myReportsIndicatorItemSelectionDialog.open(this.selectedSection);
  }

  updateSection(section: Section) {
    this.reportService.updateSection(section);
    this.sections = this.reportService.getSections();
    this._myReportsIndicatorItemSelectionDialog.close();
  }

  updateReportTitle(event: Event) {
    const title = (event.target as HTMLTextAreaElement).value;
    this.reportTitle = title;
    this.reportService.setReportTitle(title);
  }

  download(docType: string) {
    this.reportService.download(docType);
  }
}
