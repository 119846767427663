import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { AppConfig } from "../app.config";
import { Util } from '../shared/util';
import {Report, Section} from './report.model';
import {IndicatorState} from '../indicator/indicator.state';
import * as _ from 'underscore';
import { ReportDownloadFormat } from '../indicator/constraints';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class ReportService {
  private apiStatsURL: string = AppConfig.settings.server.apiStats;
  private report: Report;

  constructor(public httpClient: HttpClient) {
    let myReport = localStorage.getItem('myReport');
    if (myReport) {
      this.report = JSON.parse(myReport);
    } else {
      this.report = {
        title: "",
        sections: [],
        format: ReportDownloadFormat.NA
      }
    }
  }

  getReportTitle(): string {
    return this.report.title;
  }

  setReportTitle(title: string) {
    this.report.title = title;
    this.save();
  }

  getSection(id: string): Section {
    return this.report.sections.find(s => s.id === id);
  }

  updateSection(newSection: Section) {
    this.report.sections = this.report.sections.map(oldSection => newSection.id === oldSection.id ? newSection : oldSection);
    this.save();
  }

  addSection(section: Section) {
    this.report.sections.push(section);
    this.save();
  }

  getSections(): Section[] {
    return this.report.sections;
  }

  updateSections(sections: Section[]) {
    this.report.sections = sections;
    this.save();
  }

  removeSection(id: string) {
    this.report.sections = this.report.sections.filter(s => s.id !== id);
    this.save();
  }

  removeAllSections() {
    this.report.sections = [];
    this.save();
  }

  save() {
    localStorage.setItem('myReport', JSON.stringify(this.report));
  }

  // Generate unique id by sorting all params and then converting it to string
  generateId(state: IndicatorState): string {
    let params = _.pick(state, [
      'Name',
      'Filter',
      'View',
      'LocationSubdivision',
      'Groups',
      'Measure',
      'Compare',
    ]);

    Object.keys(params.Filter).forEach(key => {
      params.Filter[key] = params.Filter[key].sort();
    })
    params.Groups = params.Groups.sort();
    params.Measure = params.Measure.sort();
    params.Compare = params.Compare.sort();
    return JSON.stringify(params);
  }

  createReportSection(id: string = '', queryParam: string = '', title: string = '', isGraph: boolean = false, view: string = '', indicatorTitle: string = '', chartTitle: string = ''): Section {
    return {
      id: id,
      queryParam: queryParam,
      title: title,
      graph: isGraph,
      data: true,
      commentary: true,
      summary: true,
      sources: false,
      definitions: false,
      content: false,
      coding: false,
      methods: false,
      dimensions: false,
      references: false,
      view: view.toLowerCase(),
      indicator: indicatorTitle,
      chart_title: chartTitle,
    };
  }

  async exportAsPdf(data: Report) {
    var fileName = (!!data && !!data.title && !!data.title.length ? (data.title.length > 255 ? data.title.substring(255) : data.title) : "MyReport");    
    this.export(data)
    .subscribe((blob: Blob) => {
        Util.saveAs(blob, `${fileName}.${ReportDownloadFormat.PDF}`)
    });
  }

  export(data: Report): Observable<Blob> {
    var clone = JSON.parse(JSON.stringify(data));
    return this.httpClient.post<Blob>(
      `${this.apiStatsURL}/indicatorexport/pdf`, clone, {
      responseType: 'blob' as 'json',
      }
    );
  }

  public async download(docType: string) {    
    if (docType === "PDF") {
      this.report.format = ReportDownloadFormat.PDF;
      await this.exportAsPdf(this.report);
    }
    else {
      //TODO: for other document types
      console.log('Downloading ' + docType);
    }
  }
}
