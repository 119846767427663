import { IndicatorForSSRModule } from './indicator/indicator-for-ssr.module';
import { Routes, RouterModule } from "@angular/router";
import { ModuleWithProviders } from "@angular/core";
import { AppMainComponent } from "./app.main.component";
import { RedirectComponent } from './app.redirect.component';

const routes: Routes = [
  {
    path: "",
    component: AppMainComponent,
    children: [
      {
        path: "home",
        loadChildren: () =>
          import("src/app/home/home.module").then((x) => x.HomeModule),
      },
      { path: "", component: RedirectComponent , pathMatch: "full" },
      {
        path: "topics",
        loadChildren: () =>
          import("src/app/topics/topics.module").then((x) => x.TopicsModule),
      },
      {
        path: "topic-overview",
        loadChildren: () =>
          import("src/app/topic-overview/topic-overview.module").then(
            (x) => x.TopicOverviewModule
          ),
      },
      {
        path: "locations",
        loadChildren: () =>
          import("src/app/locations/locations.module").then(
            (x) => x.LocationsModule
          ),
      },
      {
        path: "location-overview",
        loadChildren: () =>
          import("src/app/location-overview/location-overview.module").then(
            (x) => x.LocationOverviewModule
          ),
      },
      {
        path: "healthstatsplus",
        loadChildren: () =>
          import("src/app/healthstatsplus/healthstatsplus.module").then(
            (x) => x.HealthstatsplusModule
          ),
      },
      {
        path: "page/:uri",
        loadChildren: () =>
          import("src/app/page/page.module").then((x) => x.PageModule),
      },
      { path: "about", redirectTo: "/page/about", pathMatch: "full" },
      {
        path: "myreports",
        loadChildren: () =>
          import("src/app/myreports/myreports.module").then(
            (x) => x.MyreportsModule
          ),
      },
      {
        path: "indicators/:indicatorName",
        loadChildren: () =>
          import("src/app/indicator/indicator-for-ssr.module").then(
            (x) => x.IndicatorForSSRModule
          ),
      },
      {
        path: "indicator",
        loadChildren: () =>
          import("src/app/indicator/indicator.module").then(
            (x) => x.IndicatorModule
          ),
      },
      {
        path: "r/:shortUrlId",
        loadChildren: () =>
          import("src/app/components/redirect/redirect.module").then(
            (x) => x.RedirectModule
          ),
      },
      {
        path: "glossary",
        loadChildren: () =>
          import("src/app/glossary/glossary.module").then(
            (x) => x.GlossaryModule
          ),
      },
      {
        path: "notfound",
        loadChildren: () =>
          import("src/app/notfound/notfound.module").then(
            (x) => x.NotFoundModule
          ),
      },
      {
        path: "search",
        loadChildren: () =>
          import("src/app/search/search.module").then((x) => x.SearchModule),
      },
      { path: "404", redirectTo: "/notfound", pathMatch: "full" },
      { path: "**", redirectTo: "/notfound" },
    ],
  },
];

export const AppRoutes: ModuleWithProviders<RouterModule> =
  RouterModule.forRoot(routes, {
    scrollPositionRestoration: "disabled",
    onSameUrlNavigation: "reload",
    anchorScrolling: "enabled",
    initialNavigation: 'enabledBlocking',
    useHash: false,
  });
