import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DialogComponent } from '../../uilib/dialog/dialog.component';
import { Section } from '../report.model';
import { ReportService } from '../report.service';

@Component({
  selector: 'app-myreports-confrimation-dialog',
  templateUrl: './myreportsconfirmationdialog.component.html',
  styleUrls: ['./myreportsconfirmationdialog.component.css']
})
export class MyReportsConfirmationDialogComponent implements OnInit {

  sections: Section[] = [];

  @ViewChild("myDialog", {static: true}) _myDialog: DialogComponent;

  constructor(private _reportService: ReportService, private _router: Router) { }

  ngOnInit() {
    this.sections = this._reportService.getSections();
  }

  open() {
    this._myDialog.open();
  }

  close() {
    this._myDialog.close();
    this._router.navigate(['myreports']);
  }

  download(docType: string) {
    this._reportService.download(docType);
  }
}
