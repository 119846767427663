import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MyReportsDialogModule } from '../myreports/myreportsdialog.module';
import { UILibModule } from '../uilib/uilib.module';
import { TopicOverviewComponent } from './topic-overview.component';

const routes = [
  { path: ':topicId', component: TopicOverviewComponent },
  { path: ':topicId/:label/:name/:locationType', component: TopicOverviewComponent },
];

@NgModule({
  declarations: [TopicOverviewComponent],
  imports: [
    CommonModule,
    UILibModule,
    MyReportsDialogModule,
    RouterModule.forChild(routes),
  ]
})
export class TopicOverviewModule { }
