import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { IndicatorForSSRComponent } from "./indicator-for-ssr/indicator-for-ssr.component";
import { UILibModule } from "../uilib/uilib.module";
import { FormsModule } from "@angular/forms";
import { MyReportsDialogModule } from "../myreports/myreportsdialog.module";
import { DocumentExportService } from "../services/documentexport.service";

const routes = [{ path: "", component: IndicatorForSSRComponent }];

@NgModule({
  declarations: [IndicatorForSSRComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    CommonModule,
    UILibModule,
    FormsModule,
    MyReportsDialogModule,
  ],
  providers: [DocumentExportService],
})
export class IndicatorForSSRModule {}
