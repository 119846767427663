// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.title-action {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  /* margin: calc(1.5 * 1rem) 0; */
}

/* .title-action > * {
    padding: 0;
    margin: 0;
} */

.title-action button {
  display: flex;
  align-items: center;
  /* margin-top: auto;
  margin-bottom: 0.33em; */
  width: -moz-fit-content;
  width: fit-content;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.title-action button:active {
  color: #D8D8D8;
}

.dialog--body {
  min-height: 400px;
}
label.checkbox {
  font-weight: bold;
}
`, "",{"version":3,"sources":["webpack://./src/app/title-action/title-action.component.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;EAC9B,qBAAqB;EACrB,gCAAgC;AAClC;;AAEA;;;GAGG;;AAEH;EACE,aAAa;EACb,mBAAmB;EACnB;0BACwB;EACxB,uBAAkB;EAAlB,kBAAkB;EAClB,YAAY;EACZ,6BAA6B;EAC7B,eAAe;AACjB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,iBAAiB;AACnB;AACA;EACE,iBAAiB;AACnB","sourcesContent":[".title-action {\r\n  display: flex;\r\n  justify-content: space-between;\r\n  align-items: baseline;\r\n  /* margin: calc(1.5 * 1rem) 0; */\r\n}\r\n\r\n/* .title-action > * {\r\n    padding: 0;\r\n    margin: 0;\r\n} */\r\n\r\n.title-action button {\r\n  display: flex;\r\n  align-items: center;\r\n  /* margin-top: auto;\r\n  margin-bottom: 0.33em; */\r\n  width: fit-content;\r\n  border: none;\r\n  background-color: transparent;\r\n  cursor: pointer;\r\n}\r\n\r\n.title-action button:active {\r\n  color: #D8D8D8;\r\n}\r\n\r\n.dialog--body {\r\n  min-height: 400px;\r\n}\r\nlabel.checkbox {\r\n  font-weight: bold;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
