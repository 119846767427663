import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { UILibModule } from '../uilib/uilib.module';
import { MyReportsConfirmationDialogComponent } from './myreportsconfirmationdialog/myreportsconfirmationdialog.component';
import { MyReportsIndicatorItemSelectionDialogComponent } from './myreportsindicatoritemselectiondialog/myreportsindicatoritemselectiondialog.component';
import { MyReportsIndicatorSelectionDialogComponent } from './myreportsindicatorselectiondialog/myreportsindicatorselectiondialog.component';

@NgModule({
  declarations: [
    MyReportsIndicatorSelectionDialogComponent,
    MyReportsIndicatorItemSelectionDialogComponent,
    MyReportsConfirmationDialogComponent
  ],
  exports: [
    MyReportsIndicatorSelectionDialogComponent,
    MyReportsIndicatorItemSelectionDialogComponent,
    MyReportsConfirmationDialogComponent
  ],
  imports: [
    CommonModule,
    UILibModule,
    DragDropModule,
    FormsModule
  ]
})
export class MyReportsDialogModule { }
