import {AfterViewInit, Component, OnInit} from '@angular/core';
import {AppMainComponent} from '../app.main.component';
import {FooterLink} from './footer-link';
import {Observable} from 'rxjs';
import {FooterService} from './footer.service';

@Component({
    selector: 'app-footer',
    templateUrl: './app-footer.component.html',
    styleUrls: ['../app-header/app-header.component.css', './app-footer.component.css'] //shares css with header
})
export class AppFooterComponent implements OnInit, AfterViewInit {
    topicLinks$: Observable<FooterLink[]>;
    indicatorLinks$: Observable<FooterLink[]>;
    publicationLinks$: Observable<FooterLink[]>;
    
    constructor(public app: AppMainComponent, private footerService: FooterService) 
    { }

    ngOnInit() {
      this.topicLinks$ = this.footerService.loadTopicLinks();
      this.indicatorLinks$ = this.footerService.loadIndicatorLinks();
      this.publicationLinks$ = this.footerService.loadPublicationLinks();
    }

    ngAfterViewInit() {
    }
  
}
