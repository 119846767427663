import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { StatsService } from '../stats.service';
import { BreadcrumbService } from '../app-breadcrumb/breadcrumb.service';
import { BreadcrumbConfig } from '../app-breadcrumb/breadcrumb.config';
import { ActivatedRoute } from '@angular/router';
import { LocationSummary } from './location-summary/location-summary.model';
import { Util } from '../shared/util';
import { MyReportsIndicatorSelectionDialogComponent } from '../myreports/myreportsindicatorselectiondialog/myreportsindicatorselectiondialog.component';
import { ReportIndicator, ReportSection } from '../myreports/myreportsindicatorselectiondialog/myreportsindicatorselectiondialog.model';
import { AppConfig } from "../app.config";
import { MetadataService } from '../metadata.service';

@Component({
  selector: 'location-overview',
  templateUrl: './location-overview.component.html',
  styleUrls: ['./location-overview.component.css']
})
export class LocationOverviewComponent implements OnInit {
  topicsList: any[] = [];
  labels: string[];
  location: LocationSummary;
  locationType: string;
  locationName: string;
  reportSections: ReportSection[] = [];

  @ViewChild("myReportsIndicatorSelectionDialog", {static: true}) _myReportsIndicatorSelectionDialog: MyReportsIndicatorSelectionDialogComponent;

  constructor(private statsService: StatsService,
    private breadcrumbService: BreadcrumbService,
    private route: ActivatedRoute,
    private metadataService: MetadataService) {
  }

  addToMyReport() {
    this._myReportsIndicatorSelectionDialog.open();
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.locationType = params['locationType'];
      this.locationName = params['name'];
      this.statsService.loadLocationSummary(this.locationType, this.locationName).subscribe(l => {
        this.location = l;

        this.breadcrumbService.updateBreadCrumbs([
          BreadcrumbConfig.LOCATIONS,
          { name: this.location.label }
        ]);

        if (!!this.location && !!this.location.label) {
          if (this.metadataService) {
            this.metadataService.updateMetadata({
              title: this.location.label,
              description: this.location.overview,
              keywords: this.location.lga ? this.location.lga?.split(",") :this.location.label.split(",")
            });
          }
        }

        this.statsService
          .loadTopics(this.location.shortLabel, this.locationType)
          .subscribe(data => {
            if (this.location && this.locationName && this.location.shortLabel && this.locationType) {

              data.forEach(item => {
                const reportIndicators: ReportIndicator[] = [];
                if (item.overview) {
                  item.overview.url = `/topic-overview/${item.name}/${this.location.shortLabel}/${this.locationName}/${this.locationType}`;
                }
                if (item.indicators) {
                  item.indicators.forEach(indicator => {
                    indicator.useUrlDirectly = true;
                    indicator.url = Util.createLocationBasedChartUrl(indicator.url, this.locationType, this.location.shortLabel);

                    const reportIndicator: ReportIndicator = {
                      name: indicator.name,
                      title: indicator.label,
                      checked: false
                    }
                    reportIndicators.push(reportIndicator);
                  });

                  const reportSection: ReportSection = {
                    title: item.title,
                    indicators: reportIndicators
                  };
                  this.reportSections.push(reportSection);
                }
              });


            }
            this.topicsList = data;
            this.labels = this.topicsList.map(item => item.title);
          });
      });
    });
  }
}
