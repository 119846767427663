import { Component, Input, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { ViewType } from '../../indicator/constraints';
import { StateManager } from '../../indicator/stateManager';
import { StatsService } from '../../stats.service';
import { CheckboxAccordionComponent } from '../../uilib/checkbox-accordion/checkbox-accordion.component';
import { DialogComponent } from '../../uilib/dialog/dialog.component';
import { MyReportsIndicatorItemSelectionDialogComponent } from '../myreportsindicatoritemselectiondialog/myreportsindicatoritemselectiondialog.component';
import { ReportService } from '../report.service';
import { ReportIndicator, ReportSection } from './myreportsindicatorselectiondialog.model';

@Component({
  selector: 'app-myreports-indicator-selection-dialog',
  templateUrl: './myreportsindicatorselectiondialog.component.html',
  styleUrls: ['./myreportsindicatorselectiondialog.component.css']
})
export class MyReportsIndicatorSelectionDialogComponent implements OnInit {
  @Input() reportSections: ReportSection[];

  @ViewChild("myDialog", {static: true}) _myDialog: DialogComponent;
 // @ViewChild("myReportsIndicatorItemSelectionDialog", {static: true}) _myReportsIndicatorItemSelectionDialog: MyReportsIndicatorItemSelectionDialogComponent;
  @ViewChildren("checkboxAccordions") _checkboxAccordions: CheckboxAccordionComponent[];

  private _stateManager: StateManager;

  constructor(private _statsService: StatsService, private _reportService: ReportService) {
    this._stateManager= new StateManager(null, null, null, null);
  }

  ngOnInit() {
  }

  open() {
    this.selectAll(false);
    this._myDialog.open();
  }

  close() {
    this._myDialog.close();
  }

  selectAll(checked: boolean): void {
    this._checkboxAccordions.forEach(checkboxAccordion => checkboxAccordion.toggleCheckbox(checked));
  }

  next() {
    const selectedReportIndicators = this.getSelectedReportSelectionIndicators();
    if (selectedReportIndicators.length > 0) {
      this
      ._statsService
      .getMetaForMultipleIndicators(selectedReportIndicators.map(indicator => indicator.name))
      .subscribe(statIndicators => {
        selectedReportIndicators
          .forEach(reportIndicator => {
            const statIndicator = statIndicators.find(indicator => indicator.name == reportIndicator.name);
            statIndicator.title = selectedReportIndicators.find(section => section.name == statIndicator.name).title;
            const params = { "name": reportIndicator.name };
            let indicatorState = this._stateManager.createState(params, statIndicator);
            const id = this._reportService.generateId(indicatorState);
            const queryParam = this._stateManager.convertStateToHttpParams(indicatorState).toString();
            const title = this.getReportTitle(statIndicator.title, indicatorState.ChartTitle);
            const reportSection = this._reportService.createReportSection(id, queryParam, title,
              (indicatorState.View != ViewType.Table),  indicatorState.View ? indicatorState.View.toString() : '',  statIndicator.title, indicatorState.ChartTitle);

            this._reportService.addSection(reportSection);
          });
        this._myDialog.close();
       // this._myReportsIndicatorItemSelectionDialog.open();
      });
    }
  }

  private getReportTitle(metaTitle: string, chartTitle: string): string {
    return `${metaTitle} ${chartTitle}`.trim();
  }

  private getSelectedReportSelectionIndicators(): ReportIndicator[] {
    const indicators: ReportIndicator[] = [];
    this.reportSections.forEach(section => {
      section.indicators.forEach(indicator => {

        const results = indicators.filter(obj => {
          return obj.name === indicator.name;
        });

        if (indicator.checked && results.length === 0) {
          indicators.push(indicator);
        }
      });
    });
    return indicators;
  }
}
