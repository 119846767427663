import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { AppConfig } from "../app.config";

@Component({
  selector: "title-action",
  templateUrl: "./title-action.component.html",
  styleUrls: ["./title-action.component.css"],
})
export class TitleActionComponent implements OnInit {
  @Output() addToMyReport = new EventEmitter();

  showMyReportComingSoon: boolean = false;
  myReportsEnabled: boolean = false;

  constructor() { }

  ngOnInit() {
    this.myReportsEnabled = AppConfig.settings.featureSettings.enableMyReport;
  }

  showAddToMyReport() {
    if (this.myReportsEnabled) {
      this.addToMyReport.emit();
    }
    else {
      this.showMyReportComingSoon = !this.showMyReportComingSoon;
    }
  }
}
