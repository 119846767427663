import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {FooterLink} from './footer-link';
import {AppConfig} from '../app.config';

@Injectable({
  providedIn: 'root'
})
export class FooterService {
  apiStatsURL: string = AppConfig.settings.server.apiStats;

  constructor(private http: HttpClient) {
  }
  
  public loadTopicLinks(): Observable<FooterLink[]> {
    return this.http.get<FooterLink[]>(`${this.apiStatsURL}/topic/toplinks`);
  }

  public loadIndicatorLinks(): Observable<FooterLink[]> {
    return this.http.get<FooterLink[]>(`${this.apiStatsURL}/indicator/toplinks`);
  }

  public loadPublicationLinks(): Observable<FooterLink[]> {
    return this.http.get<FooterLink[]>(`${this.apiStatsURL}/publication/toplinks`);
  }
  
}
