import { MetadataService } from './../metadata.service';
import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {BreadcrumbService} from '../app-breadcrumb/breadcrumb.service';
import {StatsService} from '../stats.service';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class HomeComponent implements OnInit {
  banner$: Observable<any>;
  mainLinks$: Observable<any>;
  resourceLinks$: Observable<any>;
  featureLinks$: Observable<any>;

  constructor(private statsService: StatsService, private breadcrumbService: BreadcrumbService, private metadataService: MetadataService) { }

  ngOnInit() {
    this.breadcrumbService.updateBreadCrumbs([]);
    this.banner$ = this.statsService.loadHomeBanner();
    this.mainLinks$ = this.statsService.loadHomeMainLinks();
    this.resourceLinks$ = this.statsService.loadResourceTopLinks();
    this.featureLinks$ = this.statsService.loadFeatureTopLinks();
    if (this.metadataService) {
      this.metadataService.updateMetadata({
        title: '',
        description: 'Population health data at your fingertips, Ready to use statistics, flexible analysis tools and custom reports'
      });
    }
  }

}
