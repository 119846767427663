import { Component, OnInit, ViewChild } from '@angular/core';
import { DialogComponent } from '../../uilib/dialog/dialog.component';
import { Section } from '../report.model';
import { ReportService } from '../report.service';

@Component({
  selector: 'app-myreports-indicator-item-selection-dialog',
  templateUrl: './myreportsindicatoritemselectiondialog.component.html',
  styleUrls: ['./myreportsindicatoritemselectiondialog.component.css']
})
export class MyReportsIndicatorItemSelectionDialogComponent implements OnInit {
  public reportSection: Section;

  @ViewChild("myDialog", {static: true}) _myDialog: DialogComponent;
  @ViewChild("myReportsConfrimationDialog", {static: true}) _myReportsConfrimationDialog: DialogComponent;

  public isBulkReport: boolean = false;
  public isNewReport: boolean = false;

  constructor(private _reportService: ReportService) { }

  ngOnInit() {
    this.reportSection = this._reportService.createReportSection();
  }

  open(section?: Section) {
    if (section) {
      this.isBulkReport = false;
      this.createOrUpdateSection(section);
    }
    else {
      this.isBulkReport = true;
      this.reportSection.graph = true;
    }
    this._myDialog.open();
  }

  close() {
    this._myDialog.close();
  }

  applySection() {
    if (this.isBulkReport) {
      const sections = this._reportService.getSections();
      sections.forEach(section => {
        this.copySectionSelection(section);
        this._reportService.updateSection(section);
      });
    }
    else {
      if (this.isNewReport) {
        this._reportService.addSection(this.reportSection);
      }
      else {
        this._reportService.updateSection(this.reportSection);
      }
    }
    this._myDialog.close();
    this._myReportsConfrimationDialog.open();
  }

  private createOrUpdateSection(section: Section) {
    this.reportSection = this._reportService.getSection(section.id);
    if (this.reportSection) {
      this.isNewReport = false;
    }
    else {
      this.isNewReport = true;
      this.reportSection = section;
    }
  }

  private copySectionSelection(section: Section) {
    section.data = this.reportSection.data;
    section.commentary = this.reportSection.commentary;
    section.summary = this.reportSection.summary;
    section.sources = this.reportSection.sources;
    section.definitions = this.reportSection.definitions;
    section.content = this.reportSection.content;
    section.coding = this.reportSection.coding;
    section.methods = this.reportSection.methods;
    section.dimensions = this.reportSection.dimensions;
    section.references = this.reportSection.references;
  }
}
